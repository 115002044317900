import React from "react";
import { Link } from "react-router-dom";
import { LOGO_URL } from "../headers/header";

const footer_widget = [
  {
    col: "col-xxl-2 col-xl-2 col-lg-2 offset-lg-6 col-md-3 offset-md-1 col-sm-6",
    title: "Important Links",
    footer__link: [
      { name: "Privacy Policy", link_to: "/privacy-policy" },
      {
        name: "Cancellation and Refund Policy",
        link_to: "/cancellation-and-refund-policy",
      },
      { name: "Terms of Use", link_to: "/terms-and-condition-policy" },
    ],
  },
  {
    col: "col-xxl-3 col-xl-3 col-lg-3 offset-lg-0 col-md-3 offset-md-1 col-sm-6",
    title: "Contact Us",
    footer__link: [
      { name: "Mail: info@skillzy.in", link_to: "#" },
      {
        name: "Phone: +916362074132",
        link_to: "#",
      },
      {
        name: "Address: Prestige Kew Gardens, Kariyammana Agrahara Road, Yemalur, Bangalore 560037",
        link_to: "#",
      },
    ],
  },
];
const Footer = ({ gray_bg, pt = "pt-190" }) => {
  return (
    <>
      <footer>
        <div className={`footer__area ${gray_bg ? "grey-bg-2" : "footer-bg"}`}>
          <div className={`footer__top pt-30 pb-10`}>
            <div className="container">
              <div className="row"></div>
            </div>
          </div>
          <div
            className={`footer__bottom ${gray_bg ? "footer__bottom-2" : ""}`}
          >
            <div className="container">
              <div className="row">
                {footer_widget.map((widget, index) => (
                  <div key={index} className={widget.col}>
                    <div className="footer__widget mb-50">
                      <div className="footer__widget-head mb-22">
                        <h3
                          className={`footer__widget-title ${
                            gray_bg ? "footer__widget-title-2" : ""
                          }`}
                        >
                          {widget.title}
                        </h3>
                      </div>
                      <div className="footer__widget-body">
                        <div
                          className={`footer__link ${
                            gray_bg ? "footer__link-2" : ""
                          }`}
                        >
                          <ul>
                            {widget.footer__link.map((link, index) => (
                              <li key={index}>
                                <Link to={link.link_to}>{link.name}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className={`footer__copyright ${
                    gray_bg ? "footer__copyright-2" : ""
                  } text-center`}
                >
                  <p>© {new Date().getFullYear()} All Rights Reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
